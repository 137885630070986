<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12">
        <div style="background-color:white;">
          <div class="card card-custom card-stretch card-stretch-half gutter-b">
            <!--begin::Body-->
            <div class="card-header border-0 py-5">
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <div class="d-flex flex-column mr-2">
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
                    >Weekly Subscriptions</a
                  >
                  <span class="text-muted font-weight-bold mt-2"
                    >Your Weekly Subscriptions Chart</span
                  >
                </div>
                <span
                  class="symbol symbol-light-success symbol-45"
                  v-if="!isWeeklySubscriptionLoading"
                >
                  <span class="symbol-label font-weight-bolder font-size-h6"
                    >+ {{ totalSubscriptions }}</span
                  >
                </span>
              </div>
            </div>
            <div class="card-body p-0 position-relative overflow-hidden">
              <!--begin::Chart-->
              <apexchart
                v-if="!isWeeklySubscriptionLoading"
                class="card-rounded-bottom"
                :options="chartOptions"
                :series="chartSeries"
                type="area"
              ></apexchart>
              <!--end::Chart-->

              <!--begin::Stats-->
              <div class="card-spacer mt-n25">
                <!--begin::Row-->
                <div class="row m-0">
                  <div
                    class="col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7"
                  >
                    <span
                      class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                    >
                      <inline-svg src="media/svg/icons/General/User.svg" />
                    </span>
                    <span class="text-primary font-weight-bold font-size-h6">
                      {{ subscriptionInfo.activesubscriptions }} Subscriptions
                    </span>
                  </div>
                  <div class="col bg-light-warning px-6 py-8 rounded-xl mb-7">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                    >
                      <inline-svg
                        src="media/svg/icons/Communication/Archive.svg"
                      />
                    </span>
                    <span
                      class="text-warning font-weight-bold font-size-h6 mt-2"
                    >
                      {{ subscriptionInfo.expiredsubscriptions }} Expired
                      Subscriptions</span
                    >
                  </div>
                </div>
                <!--end::Row-->
              </div>
              <!--end::Stats-->
              <div class="text-center" v-if="isWeeklySubscriptionLoading">
                <b-spinner variant="primary" label="Text Centered"></b-spinner>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Latest Subscriptions</span
              >
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->
            <div class="table-responsive" v-if="!isLatestSubscriptionLoading">
              <table class="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th class="p-0" style="min-width: 200px"></th>
                    <th class="p-0" style="width: 50px"></th>
                    <th class="p-0" style="min-width: 200px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(latestSubscription, i) in latestSubscriptions"
                  >
                    <tr v-bind:key="i">
                      <td class="pl-0">
                        <div>
                          <span class="font-weight-bolder">Start:</span
                          ><span
                            href="#"
                            class="text-muted font-weight-bold text-hover-primary"
                          >
                            {{ latestSubscription.startDate }}
                          </span>
                        </div>
                        <div>
                          <span class="font-weight-bolder">End:</span
                          ><span
                            class="text-muted font-weight-bold text-hover-primary"
                          >
                            {{ latestSubscription.endDate }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >{{ latestSubscription.productName }}</span
                          >
                        </div>
                        <div>
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg text-uppercase"
                            >{{ latestSubscription.platform }}</span
                          >
                        </div>
                      </td>
                      <td class="text-right">
                        <span class="text-muted font-weight-500">
                          {{ latestSubscription.orderId }}
                        </span>
                      </td>
                      <td class="text-right">
                        <span
                          class="label label-lg label-light-primary label-inline "
                          >{{
                            latestSubscription.isExpired ? "Expired" : "Active"
                          }}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="isLatestSubscriptionLoading">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
          <!--end::Advance Table Widget 2-->
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      isWeeklySubscriptionLoading: true,
      isLatestSubscriptionLoading: true,
      latestSubscriptions: [],
      subscriptionInfo: {},
      dailySubscriptions: {},
      totalSubscriptions: 0,
      chartOptions: {},
      chartSeries: [
        {
          name: "Subscriptions",
          data: []
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.chartOptions = {
      chart: {
        type: "area",
        height: 150,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.layoutConfig("colors.theme.base.success")]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return val;
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.light.success")],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.success")],
        strokeColor: [this.layoutConfig("colors.theme.base.success")],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
    this.loadSubscriptionStats();
    this.loadLatestSubscriptions();
  },
  methods: {
    loadSubscriptionStats() {
      return new Promise(resolve => {
        ApiService.get("subscription/stats")
          .then(({ data }) => {
            const response = data.response;
            const subscriptionInfo = response.activeSubscriptions;
            const dailySubscriptions = response.dailySubscriptions;
            let chartCategories = [];
            let chartData = [];

            dailySubscriptions.forEach(dailySubscription => {
              chartCategories.push(dailySubscription.startDate);
              chartData.push(dailySubscription.count);
              this.totalSubscriptions += dailySubscription.count;
            });

            this.chartOptions = {
              ...this.chartOptions,
              ...{
                xaxis: {
                  categories: chartCategories
                }
              }
            };

            this.chartSeries = [
              {
                name: "Subscriptions",
                data: chartData
              }
            ];
            this.subscriptionInfo = subscriptionInfo;
            this.dailySubscriptions = dailySubscriptions;
            this.isWeeklySubscriptionLoading = false;
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    },
    loadLatestSubscriptions() {
      return new Promise(resolve => {
        ApiService.get(
          "subscription?limit=5&sort[field]=startDateMs&sort[type]=desc"
        )
          .then(({ data }) => {
            let items = data.response.items;
            const products = JSON.parse(process.env.PRODUCTS);
            items.map(item => {
              item.productName = products[item.productId];
              item.startDate = moment(parseInt(item.startDateMs, 10)).format(
                "MM/DD/YYYY LT"
              );
              item.endDate = moment(parseInt(item.expirationDateMs, 10)).format(
                "MM/DD/YYYY LT"
              );
            });
            this.latestSubscriptions = items;
            this.isLatestSubscriptionLoading = false;
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    }
  }
};
</script>
