var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticStyle: { "background-color": "white" } }, [
          _c(
            "div",
            {
              staticClass:
                "card card-custom card-stretch card-stretch-half gutter-b",
            },
            [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between card-spacer flex-grow-1",
                  },
                  [
                    _vm._m(0),
                    !_vm.isWeeklySubscriptionLoading
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "symbol symbol-light-success symbol-45",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "symbol-label font-weight-bolder font-size-h6",
                              },
                              [_vm._v("+ " + _vm._s(_vm.totalSubscriptions))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "card-body p-0 position-relative overflow-hidden",
                },
                [
                  !_vm.isWeeklySubscriptionLoading
                    ? _c("apexchart", {
                        staticClass: "card-rounded-bottom",
                        attrs: {
                          options: _vm.chartOptions,
                          series: _vm.chartSeries,
                          type: "area",
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "card-spacer mt-n25" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-3x svg-icon-primary d-block my-2",
                            },
                            [
                              _c("inline-svg", {
                                attrs: {
                                  src: "media/svg/icons/General/User.svg",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-primary font-weight-bold font-size-h6",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.subscriptionInfo.activesubscriptions
                                  ) +
                                  " Subscriptions "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col bg-light-warning px-6 py-8 rounded-xl mb-7",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-3x svg-icon-warning d-block my-2",
                            },
                            [
                              _c("inline-svg", {
                                attrs: {
                                  src: "media/svg/icons/Communication/Archive.svg",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-warning font-weight-bold font-size-h6 mt-2",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.subscriptionInfo.expiredsubscriptions
                                  ) +
                                  " Expired Subscriptions"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm.isWeeklySubscriptionLoading
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-spinner", {
                            attrs: {
                              variant: "primary",
                              label: "Text Centered",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card card-custom card-stretch gutter-b" }, [
          _vm._m(1),
          _c("div", { staticClass: "card-body pt-3 pb-0" }, [
            !_vm.isLatestSubscriptionLoading
              ? _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-borderless table-vertical-center",
                    },
                    [
                      _vm._m(2),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            _vm.latestSubscriptions,
                            function (latestSubscription, i) {
                              return [
                                _c("tr", { key: i }, [
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bolder" },
                                        [_vm._v("Start:")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-muted font-weight-bold text-hover-primary",
                                          attrs: { href: "#" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                latestSubscription.startDate
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bolder" },
                                        [_vm._v("End:")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-muted font-weight-bold text-hover-primary",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                latestSubscription.endDate
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-dark-75 font-weight-bolder d-block font-size-lg",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              latestSubscription.productName
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-dark-75 font-weight-bolder d-block font-size-lg text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(latestSubscription.platform)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-muted font-weight-500",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(latestSubscription.orderId) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "label label-lg label-light-primary label-inline",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            latestSubscription.isExpired
                                              ? "Expired"
                                              : "Active"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.isLatestSubscriptionLoading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("b-spinner", {
                      attrs: { variant: "primary", label: "Text Centered" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column mr-2" }, [
      _c(
        "a",
        {
          staticClass:
            "text-dark-75 text-hover-primary font-weight-bolder font-size-h5",
          attrs: { href: "#" },
        },
        [_vm._v("Weekly Subscriptions")]
      ),
      _c("span", { staticClass: "text-muted font-weight-bold mt-2" }, [
        _vm._v("Your Weekly Subscriptions Chart"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-0 pt-5" }, [
      _c("h3", { staticClass: "card-title align-items-start flex-column" }, [
        _c("span", { staticClass: "card-label font-weight-bolder text-dark" }, [
          _vm._v("Latest Subscriptions"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "p-0", staticStyle: { "min-width": "200px" } }),
        _c("th", { staticClass: "p-0", staticStyle: { width: "50px" } }),
        _c("th", { staticClass: "p-0", staticStyle: { "min-width": "200px" } }),
        _c("th", { staticClass: "p-0", staticStyle: { "min-width": "100px" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }